import { ReactNode } from "react"

type PageContainerProps = {
  children: ReactNode
  className?: string
}

const PageContainer = ({ children, className }: PageContainerProps) => (
  <div
    className={`w-full flex flex-col justify-center items-center pt-14 md:pt-28 pb-10 ${
      className || ""
    }`}
  >
    {children}
  </div>
)

export default PageContainer
