import { SessionUser, UserWithId } from "@/types"
import { getStripe } from "@/utils/stripe/client"
import { checkoutWithStripe, createStripePortal } from "@/utils/stripe/server"
import { useRouter, usePathname } from "next/navigation"
import toast from "react-hot-toast"

interface Props {
  user: UserWithId | SessionUser | null | undefined
}

export const useStripe = ({ user }: Props) => {
  const router = useRouter()
  const currentPath = usePathname()

  const handleStripeCheckout = async (price: any) => {
    if (!user) {
      return router.push("/")
    }

    const { errorRedirect, sessionId } = await toast.promise(
      checkoutWithStripe(price, currentPath),
      {
        loading: "Loading...",
        success: "Redirecting to Stripe...",
        error: "An unknown error occurred.",
      }
    )

    if (errorRedirect) {
      toast.error("Something went wrong. Please try again later.")
    }

    if (!sessionId) {
      toast.error("Something went wrong. Please try again later.")
    }

    const stripe = await getStripe()
    // @ts-ignore
    stripe?.redirectToCheckout({ sessionId })
  }

  const handleManageSubscription = async () => {
    if (!user) {
      return router.push("/signin/signup")
    }

    try {
      const url = await toast.promise(createStripePortal(), {
        loading: "Loading...",
        success: "Redirecting to Stripe...",
        error: "An unknown error occurred.",
      })
      if (url) {
        window.location.href = url
      } else {
        console.log("An unknown error occurred.", url)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    handleStripeCheckout,
    handleManageSubscription,
  }
}
