"use client"

import { useRouter, usePathname } from "next/navigation"
import { useState } from "react"
import { SessionUser, UserWithId } from "@/types"
import { PrimaryButton } from "@/components/Button"
import PageContainer from "@/components/PageContainer"
import { useStripe } from "@/hooks/useStripe"
import { Footer } from "@/components/Footer"
import { useModal } from "@/hooks/useModal"

interface Props {
  user: UserWithId | null | undefined
  products: any[]
}

export default function Pricing({ user, products }: Props) {
  const { handleStripeCheckout, handleManageSubscription } = useStripe({
    user,
  })
  const { openSignInModal } = useModal()

  const isHomePage = usePathname() === "/"
  const intervals = Array.from(
    new Set(
      products.flatMap((product) => product?.default_price?.recurring?.interval)
    )
  )

  const router = useRouter()
  const [billingInterval, setBillingInterval] = useState<string>("month")

  if (!products.length) {
    return (
      <section>
        <div className="max-w-6xl px-4 py-8 mx-auto sm:py-24 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center"></div>
          <p className="text-4xl font-extrabold text-black sm:text-center sm:text-6xl">
            No subscription pricing plans found. Create them in your{" "}
            <a
              className="text-pink-600 underline"
              href="https://dashboard.stripe.com/products"
              rel="noopener noreferrer"
              target="_blank"
            >
              Stripe Dashboard
            </a>
            .
          </p>
        </div>
        {/* <LogoCloud /> */}
      </section>
    )
  } else {
    const userSubscriptionActive =
      user?.subscription_status === "active" ||
      user?.subscription_status === "trialing"
    const userPlan = user?.current_plan
    return (
      <PageContainer
        className={`${
          isHomePage ? "!pt-0 !pb-0" : "pt-28 pb-36 !justify-start"
        } min-h-[100svh] `}
      >
        <div className="flex flex-col items-center px-2">
          <div className="sm:flex sm:flex-col sm:align-center">
            <h1
              className={`font-extrabold text-3xl ${
                isHomePage
                  ? "text-white bg-black rounded-2xl p-4"
                  : "text-black"
              } text-center`}
            >
              Pricing Plans
            </h1>
            <p
              className={`max-w-2xl m-auto mt-2 text-md textblack sm:text-center ${
                isHomePage ? "text-white" : "text-zinc-600"
              }`}
            >
              Choose the plan that works best for your company's needs.
            </p>
            <div className="flex items-center justify-center mt-6 bg-white border-2 rounded-2xl shadow-[4px_4px_0_0_#000]">
              {/* {intervals.includes("week") && (
                <button
                  onClick={() => setBillingInterval("week")}
                  type="button"
                  className={`${
                    billingInterval === "week"
                      ? "relative w-1/2 bg-pink-600 text-white !rounded-2xl"
                      : "ml-0.5 relative w-1/2 border border-transparent text-zinc-800"
                  } outline-none rounded-md m-1 py-2 text-sm font-medium whitespace-nowrap focus:z-10 sm:w-auto sm:px-8`}
                >
                  Weekly
                </button>
              )} */}
              {intervals.includes("month") && (
                <button
                  onClick={() => setBillingInterval("month")}
                  type="button"
                  className={`${
                    billingInterval === "month"
                      ? "relative w-1/2 bg-pink-600 text-white !rounded-2xl"
                      : "ml-0.5 relative w-1/2 border border-transparent text-zinc-800"
                  } outline-none rounded-md m-1 py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8`}
                >
                  Monthly
                </button>
              )}
              {intervals.includes("year") && (
                <button
                  onClick={() => setBillingInterval("year")}
                  type="button"
                  className={`${
                    billingInterval === "year"
                      ? "relative w-1/2 bg-pink-600 text-white !rounded-2xl"
                      : "ml-0.5 relative w-1/2 border border-transparent text-zinc-800"
                  } outline-none rounded-md m-1 py-2 text-sm font-medium whitespace-nowrap focus:z-10 sm:w-auto sm:px-8`}
                >
                  Yearly
                </button>
              )}
            </div>
          </div>
          <div className="mt-6 grid grid-cols-1 lg:grid-cols-2 md:flex-row justify-center items-stretch gap-6 w-full max-w-5xl">
            <div className="flex flex-col items-center justify-start h-full w-full bg-white rounded-2xl p-6 shadow-[4px_4px_0_0_#000] border-2 border-black">
              <div className="flex flex-col justify-start items-center h-full w-full">
                <div className="w-full">
                  <h2 className="text-2xl font-semibold leading-6 text-black text-center">
                    Free
                  </h2>
                  <ul className="mt-4 text-black">
                    - 10 quotes per month
                    <br />- Custom quote page
                    <br />- Email notifications
                    <br />
                    <br />
                    <br />
                  </ul>
                </div>
                <div className="w-full">
                  <p className="mt-8 flex justify-center items-end">
                    <span className="text-5xl font-extrabold text-pink-600">
                      $0
                    </span>
                    <span className="text-base font-medium text-black">
                      /{billingInterval}
                    </span>
                  </p>
                  {/* <PrimaryButton
                    disabled={isDisabled}
                    type="button"
                    //   loading={priceIdLoading === price.id}
                    handleClick={() => {
                      if (
                        userSubscriptionActive &&
                        user?.current_plan === product.name
                      ) {
                        handleManageSubscription()
                      } else if (user) {
                        handleStripeCheckout(price)
                      } else {
                        openSignInModal()
                      }
                    }}
                    className="w-full mt-4"
                  >
                    {buttonText}
                  </PrimaryButton> */}
                </div>
              </div>
            </div>

            {products
              .sort((a, b) => {
                return (
                  b?.default_price?.unit_amount - a?.default_price?.unit_amount
                )
              })
              .map((product) => {
                const price =
                  product?.default_price?.recurring?.interval ===
                  billingInterval
                    ? product.default_price
                    : null

                let formattedDescriptionWithLineBreaks =
                  product.description.split(", ")

                // formattedDescriptionWithLineBreaks.shift()

                formattedDescriptionWithLineBreaks =
                  formattedDescriptionWithLineBreaks.map((line: string) => (
                    <li key={line} className="text-black">
                      - {line}
                    </li>
                  ))

                if (!price) return null
                const priceString = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: price.currency!,
                  minimumFractionDigits: 0,
                }).format((price?.unit_amount || 0) / 100)

                let buttonText = "Subscribe"
                if (userSubscriptionActive && userPlan === product.name) {
                  buttonText = "Manage"
                }

                let isDisabled = false

                if (userSubscriptionActive && userPlan !== product.name) {
                  isDisabled = true
                }

                const userHasTrialed = user?.has_used_trial || false

                const isYearlyPrice = billingInterval === "year"
                const yearlyPriceInMonthly = (
                  price.unit_amount / 100 / 12 || 0
                ).toFixed(0)
                const yearlyPriceFormatted = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: price.currency!,
                  minimumFractionDigits: 0,
                }).format(Number(yearlyPriceInMonthly) || 0)
                return (
                  <div
                    key={product.id}
                    className="flex flex-col items-center justify-start h-full w-full bg-white rounded-2xl p-6 shadow-[4px_4px_0_0_#000] border-2 border-black"
                  >
                    <div className="flex flex-col justify-between items-center h-full w-full">
                      <div className="w-full">
                        <h2 className="text-2xl font-semibold leading-6 text-black text-center">
                          {product.name}
                        </h2>
                        <ul className="mt-4 text-black">
                          {formattedDescriptionWithLineBreaks}
                        </ul>
                      </div>
                      <div className="w-full">
                        <p className="mt-8 flex justify-center items-end">
                          <span className="text-5xl font-extrabold text-pink-600">
                            {isYearlyPrice ? yearlyPriceFormatted : priceString}
                          </span>
                          <span className="text-base font-medium text-black">
                            /{isYearlyPrice ? "month" : billingInterval}
                          </span>
                        </p>
                        {isYearlyPrice && (
                          <p className="text-sm text-center text-zinc-700 mt-2">
                            <em>Paid annually</em>
                          </p>
                        )}
                        {userHasTrialed !== true && (
                          <p className="text-sm text-center text-zinc-700 mt-2">
                            <em>7 day free trial!</em>
                          </p>
                        )}
                        <PrimaryButton
                          disabled={isDisabled}
                          type="button"
                          //   loading={priceIdLoading === price.id}
                          handleClick={() => {
                            if (
                              userSubscriptionActive &&
                              user?.current_plan === product.name
                            ) {
                              handleManageSubscription()
                            } else if (user) {
                              handleStripeCheckout(price)
                            } else {
                              openSignInModal()
                            }
                          }}
                          className="w-full mt-4"
                        >
                          {buttonText}
                        </PrimaryButton>
                      </div>
                    </div>
                  </div>
                )
              })
              .reverse()}
          </div>
          {/* <LogoCloud /> */}
        </div>
        <Footer absolute />
      </PageContainer>
    )
  }
}
